<template>
  <div class="report-container">
    <div class="report-title-box">
      <div>已购服务</div>
      <div class="title-right">
        <span class="service">客服电话：</span>
        <span>{{servicePhone}}</span>
      </div>
    </div>
    <div class="divider"></div>
    <div v-if="total == 0" style="margin-top: 150px">
      <empty name="暂无数据"></empty>
    </div>
    <div class="report-content" v-show="total != 0">
      <div class="report-box">
        <el-table
          :data="tableData"
          style="width: 100%"
          header-cell-class-name="table_header"
          height="645px"
        >
          <el-table-column
            type="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column
            prop=""
            label="文件名称"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span class="view-style" @click="handleDateil(scope.row)">{{
                scope.row.fileName
              }}</span>
            </template></el-table-column
          >
          <el-table-column prop="type" label="服务类型">
            <template slot-scope="scope"
              >{{ scope.row.serviceTypeName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="purchaseDate"
            label="购买日期"
            width="180"
          ></el-table-column>
          <el-table-column prop="" label="留言">
            <template slot-scope="scope">
              <span
                v-if="scope.row.remark"
                class="view-style"
                @click="handleRemark(scope.row)"
                >查看</span
              >
              <span v-if="!scope.row.remark">--</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="状态">
            <template slot-scope="scope"
              ><span
                :class="
                  scope.row.purchasedState == '已完成'
                    ? '60601001'
                    : scope.row.purchasedState == '已下载'
                    ? '60601004'
                    : scope.row.purchasedState == '未下载'
                    ? '60601003'
                    : scope.row.purchasedState == '交付中'
                    ? '60601002'
                    : ''
                "
                >{{
                  scope.row.purchasedState == "60601001"
                    ? "已完成"
                    : scope.row.purchasedState == "60601002"
                    ? "交付中"
                    : scope.row.purchasedState == "60601003"
                    ? "未下载"
                    : scope.row.purchasedState == "60601004"
                    ? "已下载"
                    : ""
                }}</span
              ></template
            >
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <pagination
          ref="pagination"
          :total="total"
          @change="paginChange"
          v-show="total != 0"
        >
        </pagination>
      </div>
    </div>
    <!-- 互动弹窗dialog -->
    <Modal :visible.sync="visible" @reset="resetFields" class="dialog-style">
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
        查看留言
      </div>
      <el-form
        :model="dataPO"
        ref="dataPO"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="留言内容"
              prop="remark"
              class="span-item title-item"
            >
              <span>{{ dataPO.remark }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Modal from "@/components/Modal";
import Empty from "@/components/Empty";
import { mapGetters } from "vuex";
export default {
  components: {
    Pagination,
    Modal,
    Empty
  },
  data() {
    return {
      total: 0,
      tableData: [],
      visible: false,
      dataPO: {},
      typeList: [
        {
          dataId: "172",
          label: "研报",
          dictSort: "1",
          dictType: "6048",
          value: "60481001",
          isDefault: "10011002",
          params: {},
          parentId: "0",
          state: "10021001",
          status: "10021001"
        },
        {
          dataId: "173",
          label: "会员开通",
          dictSort: "2",
          dictType: "6048",
          value: "60481002",
          isDefault: "10011002",
          params: {},
          parentId: "0",
          state: "10021001",
          status: "10021001"
        },
        {
          dataId: "179",
          label: "研究报告定制",
          dictSort: "0",
          dictType: "6051",
          value: "60511001",
          isDefault: "10011002",
          params: {},
          parentId: "0",
          state: "10021001",
          status: "10021001"
        },
        {
          dataId: "180",
          label: "中标数据定制",
          dictSort: "0",
          dictType: "6051",
          value: "60511002",
          isDefault: "10011002",
          params: {},
          parentId: "0",
          state: "10021001",
          status: "10021001"
        },
        {
          dataId: "181",
          label: "咨询服务定制",
          dictSort: "0",
          dictType: "6051",
          value: "60511003",
          isDefault: "10011002",
          params: {},
          parentId: "0",
          state: "10021001",
          status: "10021001"
        }
      ],
      params: {
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  computed:{
    ...mapGetters(['servicePhone'])
  },
  created() {},
  mounted() {
    this.pageConsumerPurchase();
  },
  methods: {
    // 获取列表
    pageConsumerPurchase() {
      this.$api.personalCenter
        .pageConsumerPurchase(this.params)
        .then(res => {
          this.tableData = [];
          res.rows.forEach(item => {
            this.typeList.forEach(items => {
              if (items.value == item.serviceType) {
                item.serviceTypeName = items.label;
              }
            });
            this.tableData.push(item);
          });
          this.total = res.total;
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 文件名称查看详情
    handleDateil(val) {
      if (val.serviceType.indexOf("6048") != -1) {
        // 研报跳转详情
        this.$api.personalCenter
          .getReportId({
            relationId: val.relationId
          })
          .then(response => {
            this.$api.research
              .getReadAndDownload({
                reportId: response.data.commodityId
              })
              .then(res => {
                let data = res.data;
                let type = "";
                if (val.reportType == "10011001") {
                  type = 1;
                } else if (val.reportType == "10011002") {
                  type = 2;
                }
                if (data.read == "10011001") {
                  // 有阅读权限
                  let url = this.$router.resolve({
                    path: `/research/market-detail?type=${type}&id=${response.data.commodityId}`
                  });
                  window.open(url.href, "_blank");
                } else {
                  // 没有阅读权限
                  let url = this.$router.resolve({
                    path: `/research/market-preview?type=${type}&id=${response.data.commodityId}`
                  });
                  window.open(url.href, "_blank");
                }
              })
              .catch(msg => {
                if (msg?.msg) {
                  this.$message.error(msg?.msg);
                }
              });
          })
          .catch(msg => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      } else if (val.serviceType.indexOf("6051") != -1) {
        // 定制判断下载  purchasedId
        if (val.purchasedState == "60601002") {
          // 交付中 给提示
          this.$message.error("请等待交付完成后查看");
          return;
        } else if (
          val.purchasedState == "60601003" ||
          val.purchasedState == "60601004"
        ) {
          console.log(
            "purchasedId",
            val.purchasedId,
            `${val.fileName}.${val.fileExtension}`
          );
          const loading = this.$loading({
            lock: true,
            text: "下载中...",
            spinner: "el-icon-loading",
            background: "rgba(255, 255, 255, 0.3)",
            fullscreen: false
          });
          // 未下载 已下载  均点击下载
          this.$api.personalCenter
            .downloadPurchase({
              purchasedId: val.purchasedId
            })
            .then(res => {
              this.$utils.downloadFile(
                res,
                `${val.fileName}.${val.fileExtension}`
              );
              loading.close();
              this.pageConsumerPurchase();
              this.$forceUpdate();
            })
            .catch(msg => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
              loading.close();
            });
        }
        // else if (val.purchasedState == "60601004") {
        // }
      }
    },
    // 查看留言
    handleRemark(val) {
      // 查看留言
      this.visible = true;
      this.dataPO = val;
    },
    // 切换分页
    paginChange(page, pageSize) {
      this.params.pageNum = page;
      this.params.pageSize = pageSize;
      this.pageConsumerPurchase();
    },
    // 关闭弹窗时重置表单
    resetFields() {
      this.$refs.dataPO.resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
